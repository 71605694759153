<template>
  <div
    class="flex items-center text-white gap-2 rounded-[8px] py-2 overflow-hidden"
    :class="{ 'justify-center': isCentered }"
    :style="{background: activePromoData.advisorCardBannerBgColor}"
  >
    <div
      class="w-fit font-bold italic py-[3px] leading-[1.2] text-b-s"
      :class="[activePromoData.ribbonStyle, isCentered ? 'centered' : 'half']"
      :style="{
                backgroundColor: activePromoData.ribbonBgColor,
                color: activePromoData.ribbonTextColor
              }"
    >
      <p class="whitespace-nowrap">{{ activePromoData.advisorCardBannerTitle }}</p>
    </div>
    <div class="whitespace-nowrap text-h-s">
      <span v-if="advisor.free_minutes_left">{{ advisor.free_minutes_left }} Free min</span>
      <span v-if="advisor.free_minutes_left && advisor.discount">&nbsp;•&nbsp;</span>
      <span v-if="advisor.discount">{{ advisor.discount}}% OFF</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {usePromoBanner} from "@/src/shared/helpers/promoBanner";
import {withDefaults} from "vue";

const props = withDefaults(defineProps<{
  advisor: any
  isCentered?: boolean
}>(), {
  isCentered: false
});
const {activePromoData} = usePromoBanner()
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorCardDiscountBanner',
};
</script>

<style lang="scss">
.advisor-card, .advisor-details {
  .rounded-split {
    @apply rounded-8 px-12;
    &.half {
      @apply rounded-l-none;
    }
  }
  .rounded-separate {
    @apply rounded-[4px] px-12;
    &.half {
      @apply rounded-l-none;
    }
  }
  .ribbon {
    &.half {
      @apply px-16;
      --f: 0px; /* control the folded part*/
      --r: .6em; /* control the ribbon shape */
      
      padding-inline: 12px;
      left: 0;
      border-right: var(--r) solid #0000;
      clip-path: polygon(
          100% 0,
          0 0,
          0 calc(100% - var(--f)),
          var(--f) calc(100% - var(--f)),
          100% calc(100% - var(--f)),
          calc(100% - var(--r)) calc(50% - var(--f) / 2)
      );
    }
    &.centered {
      @apply w-full xs:w-fit px-16 py-1 gap-20;
      --r: .6em;
      line-height: 16px;
      clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
    }
  }
}
</style>